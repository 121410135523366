'use client'

import React, {useEffect, useRef, useState} from 'react';
import {Field, Label, Switch} from '@headlessui/react'
import {FcCommandLine} from "react-icons/fc";
import {AiOutlineFundProjectionScreen} from "react-icons/ai";
import {BiLogoWordpress} from "react-icons/bi";
import {BsTelephone} from "react-icons/bs";
import {GrLanguage} from "react-icons/gr";
import {MdOutlineMarkEmailRead} from "react-icons/md";
import { GiWorld } from "react-icons/gi";

import ProgrammingLanguageWidget from "../components/ProgrammingLanguageWidget";
import SponsorWidget from "../components/SponsorWidget";
import programmingLanguageFunction from "../hooks/programmingLanguageFunction";

const Contact = () => {
    const [agreed, setAgreed] = useState(false);

    const [formData, setFormData] = useState({
        formType: 'contact', // Identificador del formulario
        firstName: '',
        lastName: '',
        company: '',
        email: '',
        phone: '',
        message: '',
        agreed: false,
    });

    const words = ['WORK !', 'HELP !']; // Words to alternate
    const {openText} = programmingLanguageFunction(words); // Use the custom hook

    // Crear referencia al formulario de contacto
    const contactFormRef = useRef(null);

    // Función para hacer scroll al formulario
    const handleScrollToForm = () => {
        contactFormRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    const [submitMessage, setSubmitMessage] = useState(''); // Estado para el mensaje de envío
    const [isError, setIsError] = useState(false); // Estado para definir si hubo un error

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };
    const handleAgreeChange = () => {
        setFormData({
            ...formData,
            agreed: !formData.agreed,
        });
    };

    //envio de formulario
    const handleSubmit = async (e) => {
        e.preventDefault();


        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/mail.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSubmitMessage('Your message has been sent successfully!'); // Mensaje de éxito
                setIsError(false); // Indica que no es un error


                setFormData({
                    firstName: '',
                    lastName: '',
                    company: '',
                    email: '',
                    phone: '',
                    message: '',
                    agreed: false,
                });


            } else {
                setSubmitMessage('Failed to send message. Please try again.'); // Mensaje de error
                setIsError(true); // Indica que hubo un error
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmitMessage('An error occurred. Please try again later.'); // Mensaje de error
            setIsError(true); // Indica que hubo un error
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Hacer scroll a la parte superior al montar el componente
    }, []);
    return (
        <>
            <div className="space-y-8 grow md:flex md:space-y-0 md:space-x-8">
                {/* Middle area */}
                <div className="grow">
                    <div className="max-w-[700px]">
                        <div className="space-y-10">
                            <section>
                                {/* Page title */}
                                <div
                                    className="flex items-center mb-5"> {/* Alinea verticalmente el ícono y el texto */}
                                    <FcCommandLine
                                        className="mr-1 text-5xl"/> {/* Añade margen a la derecha del ícono */}
                                    <h1 className="h1 font-aspekta">Team Up and Build!
                                    </h1>
                                </div>
                                {/* Page content */}
                                <div className="space-y-8 text-slate-500 dark:text-slate-400">
                                    <p className="text-lg">
                                        I’m excited to help you take the next steps in your digital journey. Whether you
                                        have questions or ideas to share, I'm here to connect and collaborate. Let’s
                                        bring your vision to life! </p>
                                    <ul className="space-y-4">
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span>Growing Together</span>

                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span>Passion for Digital Solutions</span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span>One-on-One Support</span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span>An independent viewpoint.</span>
                                        </li>
                                    </ul>
                                    <div>
                                        <section>
                                            {/* Cards */}
                                            <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
                                                <a className="p-5 transition-transform duration-700 ease-in-out border rounded-lg cursor-default border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:-rotate-1 even:rotate-1 hover:rotate-0 hover:duration-100"
                                                   href="#0">
                                                    <div className="flex flex-col h-full">
                                                        <div className="grow">
                                                            <AiOutlineFundProjectionScreen color="#0ea5e9" size="20px"/>
                                                            <div
                                                                className="text-lg font-aspekta font-[650] mb-1">Project
                                                                Management & Development
                                                            </div>
                                                            <p className="mb-2 text-sm text-slate-500 dark:text-slate-400">An
                                                                outgoing person, 100% committed to every task and always
                                                                looking for solutions. I hope we can work together again
                                                                in the future. Wishing you much success!</p>
                                                        </div>
                                                        <div className="text-xs font-aspekta font-[650] text-sky-500">—
                                                            Federico Pais
                                                        </div>
                                                    </div>
                                                </a>
                                                <a className="p-5 transition-transform duration-700 ease-in-out border rounded-lg cursor-default border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:-rotate-1 even:rotate-1 hover:rotate-0 hover:duration-100"
                                                   href="#0">
                                                    <div className="flex flex-col h-full">
                                                        <div className="grow">
                                                            <BiLogoWordpress color="#0ea5e9" size="20px"/>
                                                            <div
                                                                className="text-lg font-aspekta font-[650] mb-1">WordPress
                                                                LMS
                                                            </div>
                                                            <p className="mb-2 text-sm text-slate-500 dark:text-slate-400">It's
                                                                amazing how Oscar always goes the extra mile, he's such
                                                                a helpful person. Thank you for all your hard work!</p>
                                                        </div>
                                                        <div className="text-xs font-aspekta font-[650] text-sky-500">—
                                                            Rodrigo Laso
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </section>

                                        {/* Subscribe form */}
                                        <div ref={contactFormRef}
                                             className="px-6 py-24 mt-8 border-2 rounded-lg isolate bg-gray-50 sm:py-12 lg:px-0">
                                            <div className="max-w-2xl mx-auto text-center ">
                                                <h4 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">Contact
                                                    Me, <span
                                                        className="relative inline-flex text-sky-500 before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:-rotate-2 before:translate-y-1/4">Let's Talk</span>,
                                                    and Start Going Digital!</h4>
                                            </div>
                                            <form onSubmit={handleSubmit} action="#" method="POST"
                                                  className="max-w-xl mx-auto mt-16 sm:mt-20">
                                                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                                    <div>
                                                        <label htmlFor="first-name"
                                                               className="block text-sm leading-6 text-gray-900 dark:text-white">
                                                            <span className="required">*</span> First name
                                                        </label>
                                                        <div className="mt-2.5">
                                                            <input
                                                                id="firstName"
                                                                name="firstName"
                                                                type="text"
                                                                autoComplete="given-name"
                                                                value={formData.firstName}
                                                                onChange={handleChange}
                                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="last-name"
                                                               className="block text-sm leading-6 text-gray-900">
                                                            Last name
                                                        </label>
                                                        <div className="mt-2.5">
                                                            <input
                                                                id="lastName"
                                                                name="lastName"
                                                                type="text"
                                                                value={formData.lastName}
                                                                autoComplete="family-name"
                                                                onChange={handleChange}
                                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label htmlFor="company"
                                                               className="block text-sm leading-6 text-gray-900">
                                                            Company
                                                        </label>
                                                        <div className="mt-2.5">
                                                            <input
                                                                id="company"
                                                                name="company"
                                                                type="text"
                                                                autoComplete="organization"
                                                                value={formData.company}
                                                                onChange={handleChange}
                                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label htmlFor="email"
                                                               className="block text-sm leading-6 text-gray-900">
                                                            <span className="required">*</span> Email
                                                        </label>
                                                        <div className="mt-2.5">
                                                            <input
                                                                id="email"
                                                                name="email"
                                                                type="email"
                                                                autoComplete="email"
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label htmlFor="phone-input"
                                                               className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                            Phone number:
                                                        </label>
                                                        <div className="relative ">
                                                            <div
                                                                className="absolute inset-y-0 left-0 flex items-center pl-3 mr-2 pointer-events-none">
                                                                <svg
                                                                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                                                    aria-hidden="true"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="currentColor" viewBox="0 0 19 18">
                                                                    <path
                                                                        d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z"/>
                                                                </svg>
                                                            </div>
                                                            <input type="text"
                                                                   id="phone-input"
                                                                   name="phone"
                                                                   aria-describedby="helper-text-explanation"
                                                                   className="block w-full rounded-md border-0 px-3.5 py-2 pl-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                                   value={formData.phone}
                                                                   onChange={handleChange}
                                                                   placeholder="+1 123-456-7890" required/>
                                                        </div>
                                                        <p id="helper-text-explanation"
                                                           className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                                            Select a phone number that matches the format.
                                                        </p>
                                                    </div>

                                                    <div className="sm:col-span-2">
                                                        <label htmlFor="message"
                                                               className="block text-sm leading-6 text-gray-900">
                                                            <span className="required">*</span> Message
                                                        </label>
                                                        <div className="mt-2.5">
                                                              <textarea
                                                                  id="message"
                                                                  name="message"
                                                                  rows={4}
                                                                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-500 sm:text-sm sm:leading-6"
                                                                  value={formData.message}
                                                                  onChange={handleChange}
                                                                  defaultValue={''}
                                                                  required
                                                              />
                                                        </div>
                                                    </div>
                                                    <Field className="flex gap-x-4 sm:col-span-2">
                                                        <div className="flex items-center h-6">
                                                            <Switch
                                                                // onChange={setAgreed}
                                                                className="group flex w-8 flex-none cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 data-[checked]:bg-sky-500"
                                                                checked={formData.agreed}
                                                                onChange={handleAgreeChange}
                                                                required
                                                            >
                                                                <span className="sr-only">Agree to policies</span>
                                                                <span
                                                                    aria-hidden="true"
                                                                    className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
                                                                />
                                                            </Switch>
                                                        </div>
                                                        <Label className="text-sm leading-6 text-gray-600">
                                                            By selecting this, you agree to our{' '}
                                                            <a href="/" className="font-semibold text-sky-500">
                                                                privacy&nbsp;policy
                                                            </a>
                                                            .
                                                        </Label>
                                                    </Field>
                                                </div>
                                                <div className="mt-10">
                                                    <button
                                                        type="submit"
                                                        className="block w-full rounded-md  bg-sky-500  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:hover:bg-sky-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                    >
                                                        Let's talk
                                                    </button>
                                                    {submitMessage && (
                                                        <p className={`mt-4 message ${isError ? 'message-error' : 'message-success'}`}>
                                                            {submitMessage}
                                                        </p>
                                                    )}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>

                {/*Right sidebar*/}
                <aside className="md:w-[240px] lg:w-[300px] shrink-0">

                    <div className="space-y-6">

                        {/* Programming language widget*/}
                        <ProgrammingLanguageWidget openText={openText} handleSubmit={handleSubmit}
                                                   onContactClick={handleScrollToForm}/>

                        {/* Sponsor widget */}
                        <SponsorWidget/>

                        { /*Popular services widget*/}
                        <div
                            className="p-5 border rounded-lg border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:rotate-1 even:-rotate-1">
                            <div className="font-aspekta font-[550] mb-3">Optimized Digital Solutions</div>
                            <ul className="space-y-3">
                                <li className="inline-flex">
                                    <span className="mr-2 text-sky-500">—</span>
                                    <p className="relative inline-flex text-sm duration-150 ease-out font-aspekta hover:text-sky-500 before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Custom Applications
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="mr-2 text-sky-500">—</span>
                                    <p className="relative inline-flex text-sm duration-150 ease-out font-aspekta hover:text-sky-500 before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Consulting on digital strategy
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="mr-2 text-sky-500">—</span>
                                    <p className="relative inline-flex text-sm duration-150 ease-out font-aspekta hover:text-sky-500 before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Integrating e-commerce solutions
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="mr-2 text-sky-500">—</span>
                                    <p className="relative inline-flex text-sm duration-150 ease-out font-aspekta hover:text-sky-500 before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Developing interactive websites
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="mr-2 text-sky-500">—</span>
                                    <p className="relative inline-flex text-sm duration-150 ease-out font-aspekta hover:text-sky-500 before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Providing ongoing technical support
                                    </p>
                                </li>
                            </ul>

                        </div>

                    </div>
                    {/*Contact information*/}
                    <div
                        className="rounded-lg border mt-6 border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:rotate-1 even:-rotate-1 p-5">
                        <div className="font-aspekta font-[550] mb-3">Contact Information</div>
                        <ul className="space-y-3">

                            {/* Email */}
                            <li className="flex justify-between items-center">
                                <div className="grow inline-flex mr-1.5 truncate">
                                    < MdOutlineMarkEmailRead color="#0ea5e9" size="20px"/>

                                    <span
                                        className="font-aspekta font-[500] text-sm ml-2">  info@oscarleecorradine.com</span>
                                </div>
                            </li>

                            {/* Phone */}
                            <li className="flex justify-between items-center">
                                <div className="grow inline-flex mr-1.5 truncate">
                                    < BsTelephone color="#0ea5e9" size="20px"/>
                                    <span className="font-aspekta font-[500] text-sm ml-2"> +43 676 5777 358</span>
                                </div>
                            </li>

                            {/* Languages I Speak */}
                            <li className="flex justify-between items-center">
                                <div className="grow inline-flex mr-1.5 truncate">
                                    < GrLanguage color="#0ea5e9" size="20px"/>
                                    <span className="font-aspekta font-[500] text-sm ml-2">Spanish, English, German, Chinese</span>
                                </div>
                            </li>

                            {/* Country */}
                            <li className="flex justify-between items-center">
                                <div className="grow inline-flex mr-1.5 truncate">
                                    < GiWorld color="#0ea5e9" size="20px"/>
                                 <span className="font-aspekta font-[500] text-sm ml-2"> Linz-Austria</span>
                                </div>
                                </li>

                        </ul>
                    </div>

                </aside>
            </div>
        </>
    );
};

export default Contact;
