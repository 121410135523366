
import ProgrammingLanguageWidget from '../components/ProgrammingLanguageWidget';
import SponsorWidget from "../components/SponsorWidget";


import programmingLanguageFunction from "../hooks/programmingLanguageFunction";
import ProjectCard from '../components/ProjectCard';


import { FaLaptopCode } from "react-icons/fa";
import { HiOutlineEmojiHappy } from "react-icons/hi";
import { FaRegHandshake } from "react-icons/fa";
import React, {useEffect} from "react";


const Projects = () => {
    const words = ['WORK!', 'HELP!']; // Words to alternate
    const { openText, handleSubmit } = programmingLanguageFunction(words); // Use the custom hook

    useEffect(() => {
        window.scrollTo(0, 0); // Hacer scroll a la parte superior al montar el componente
    }, []);

    const sideHustles = [
        {
            title: 'Fundación VOS',
            description: 'Website Development, support in the digitization of the foundation, creation of forms, CRM, and solution for receiving online payments. ',
            imgSrc: <FaRegHandshake color="#0ea5e9" size="20px" />,
            imgAlt: 'kids-Foundation',
            tag: 'Non-profit organization',
            link: 'https://fundacionvos.org',
        },
        {
            title: 'Equilibrio App',
            description: 'PWA/Landing Page, frontend development for the apps, project management, and development of the concept and digital implementation.',
            imgSrc: <FaRegHandshake color="#0ea5e9" size="20px" />,
            imgAlt: 'Project 02',
            tag: 'Non-profit organization',
            link: 'https://equilibrio.app',
        },
        // Add more projects here
    ];

    const clientProjects = [
        {
            title: 'Personal Portfolio',
            description: 'A React app styled with Tailwind CSS, focused on creating new contacts, and seeking new projects, tasks, or job opportunities.',
            imgSrc: <HiOutlineEmojiHappy color="#0ea5e9" size="20px" />,
            imgAlt: 'Project 04',
            tag: 'Personal Development',
            link: '/'
        },
        {
            title: 'MentaPixel',
            description: 'Developed a landing page in React.js, including design implementation and backend configuration in PHP for email processing.',
            imgSrc: <FaLaptopCode color="#0ea5e9" size="20px"/>,
            imgAlt: 'LandingPage',
            tag: 'Full-Stack Development Task',
            link: 'https://mentapixel.com'
        }
        // Add more projects here
    ];
    const codeText = [
        "<🚧>This section is still under construction; more projects and detailed information about each one are coming soon. </🚧>"
    ];
    const {openText: animatedCodeText} = programmingLanguageFunction(codeText, 60, 5); // Using hook for animated code text
    const splitTextProjects = animatedCodeText.split(/(<\/?🚧>)/g);

    return (
        <div>
            {/* Content*/}
            <div className="grow md:flex space-y-8 md:space-y-0 md:space-x-8  pb-16 md:pb-20">
                {/* Mitle area*/}
                <div className="grow">
                    <div className="max-w-[700px]">
                        <section>
                            <h1 className="h1 font-aspekta mb-12">Nice stuff I've built</h1>

                            {/* Animated Code Block */}
                            <pre
                                className="whitespace-pre-wrap text-sm text-black bg-gray-100 my-6 p-4 rounded leading-tight relative">
                                    <code className="font-pt-mono typing ">
                                        {splitTextProjects.map((part, index) => (
                                            <span
                                                key={index}
                                                className={part.includes('<p>') || part.includes('</p>') ? 'text-yellow-400' : ''}
                                            >
                                                {part}
                                            </span>
                                        ))}
                                        <span className="cursor">|</span>
                                    </code>
                                </pre>

                            {/* Client Projects Section */}
                            <section>
                                <h2 className="font-aspekta text-xl font-[650] mb-6">General Projects</h2>
                                <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-5">
                                    {clientProjects.map((project, index) => (
                                        <ProjectCard
                                            key={index}
                                            title={project.title}
                                            description={project.description}
                                            imgSrc={project.imgSrc}
                                            imgAlt={project.imgAlt}
                                            tag={project.tag}
                                            link={project.link}
                                        />
                                    ))}
                                </div>
                            </section>

                            {/* Side Hustles Section */}
                            <section>
                                <h2 className="font-aspekta my-6 text-xl font-[650]  ">Collaborations with Non-Profits</h2>
                                <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-5">
                                    {sideHustles.map((project, index) => (
                                        <ProjectCard
                                            key={index}
                                            title={project.title}
                                            description={project.description}
                                            imgSrc={project.imgSrc}
                                            imgAlt={project.imgAlt}
                                            tag={project.tag}
                                            link={project.link}
                                        />
                                    ))}
                                </div>
                            </section>
                        </section>
                    </div>
                </div>

                {/*Right sidebar*/}
                <aside className="md:w-[240px] lg:w-[300px] shrink-0">

                    <div className="space-y-6">

                        {/* Programming language widget*/}
                        <ProgrammingLanguageWidget openText={openText} handleSubmit={handleSubmit} />


                        {/* Sponsor widget */}
                        <SponsorWidget/>

                        { /*Popular services widget*/}
                        <div
                            className="rounded-lg border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:rotate-1 even:-rotate-1 p-5">
                            <div className="font-aspekta font-[550] mb-3">Optimized Digital Solutions</div>
                            <ul className="space-y-3">
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Custom Applications
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Consulting on digital strategy
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Integrating e-commerce solutions
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Developing interactive websites
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Providing ongoing technical support
                                    </p>
                                </li>
                            </ul>

                        </div>

                    </div>

                </aside>

            </div>
        </div>

    );
};

export default Projects;
