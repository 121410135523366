
import profileImg from '../assets/images/oscarleecorradine-bg0-cnvs.png';
import '../App.css';
import ProgrammingLanguageWidget from '../components/ProgrammingLanguageWidget';
import SponsorWidget from "../components/SponsorWidget";
import { Link } from 'react-router-dom';
import React, {useEffect} from 'react';



import programmingLanguageFunction from "../hooks/programmingLanguageFunction";





const Home = () => {
    const words = ['WORK !', 'HELP !']; // Words to alternate
    const { openText, handleSubmit } = programmingLanguageFunction(words); // Use the custom hook

    useEffect(() => {
        window.scrollTo(0, 0); // Hacer scroll a la parte superior al montar el componente
    }, []);

    return (
        <div>
            {/* Content*/}
            <div className="grow md:flex space-y-8 md:space-y-0 md:space-x-8 pb-16 md:pb-20">

                {/* Mitle area*/}
                <div className="grow">
                    <div className="max-w-[700px]">

                        <section className="">
                            {/* Page title*/}
                            <h1 className="h1 font-aspekta mb-5">Hey. I'm Oscar <span
                                className="inline-flex relative text-sky-500 before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:-rotate-2 before:translate-y-1/4">Welcome</span> to
                                my portfolio👋!</h1>
                            <div className="flex justify-center my-6">
                                <img className=" lg:w-64 md:w-48 sm:w-24 h-auto my-6 rounded-xl shadow-xl " src={profileImg} alt="About"/>
                            </div>
                            {/*Page Content*/}
                            <div className="text-slate-500 dark:text-slate-400 space-y-8">
                                <div className="space-y-4">
                                    <h2 className="h3 font-aspekta text-slate-800 dark:text-slate-100">Short Bio</h2>
                                    <p>
                                        I’m a versatile professional with over 10 years of experience in business,
                                        market research, project management, supply chain, and product development. In
                                        recent years, I’ve shifted my focus towards <span
                                        className="font-medium text-sky-500">frontend development</span> and <span
                                        className="font-medium text-sky-500">digital
                                        transformation</span>, driven by a passion for creating responsive, user-focused
                                        solutions. My combined expertise in technology and business allows me to
                                        approach challenges from multiple perspectives and consistently deliver
                                        high-quality results.
                                    </p>
                                </div>
                                <div className="space-y-4">
                                    <h2 className="h3 font-aspekta text-slate-800 dark:text-slate-100">Career</h2>
                                    <p>
                                        Throughout my career, I’ve combined my skills in <span
                                        className="font-medium text-sky-500">frontend development</span>,<span
                                        className="font-medium text-sky-500"> project management </span>and <span
                                        className="font-medium text-sky-500">international consulting,</span> allowing
                                        me to tackle complex challenges from both technical and strategic angles.
                                    </p>
                                </div>
                                <div className="space-y-8">
                                    <h2 className="h3 font-aspekta text-slate-800 dark:text-slate-100">Experience</h2>
                                    <ul className="space-y-8">
                                        {/* Item*/}
                                        <li className="relative group">
                                            <div
                                                className="flex items-start before:absolute before:left-0 before:h-full before:w-px before:bg-slate-200 before:dark:bg-slate-800 before:self-start before:ml-[28px] before:-translate-x-1/2 before:translate-y-8 before:group-last-of-type:hidden">
                                                <div
                                                    className="absolute left-0 h-14 w-14 flex items-center justify-center border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 bg-white dark:bg-slate-900 rounded-full">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="red"
                                                         className="size-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"/>
                                                    </svg>
                                                </div>
                                                <div className="pl-20 space-y-1">
                                                    <div className="text-xs text-slate-500 uppercase">Linz <span
                                                        className="text-slate-400 dark:text-slate-600">·</span> Austria
                                                    </div>
                                                    <div
                                                        className="font-aspekta font-[650] text-slate-800 dark:text-slate-100">Frontend
                                                        Developer
                                                    </div>
                                                    <div className="text-sm text-slate-500 dark:text-slate-400">I’ve
                                                        worked on creating efficient, user-focused solutions, building
                                                        custom React blocks, optimizing responsive interfaces, and
                                                        enhancing user interaction through interactive JavaScript games.
                                                        I stay updated with the latest technologies to apply best
                                                        practices in all my projects.

                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        {/* Item*/}
                                        <li className="relative group">
                                            <div
                                                className="flex items-start before:absolute before:left-0 before:h-full before:w-px before:bg-slate-200 before:dark:bg-slate-800 before:self-start before:ml-[28px] before:-translate-x-1/2 before:translate-y-8 before:group-last-of-type:hidden">
                                                <div
                                                    className="absolute left-0 h-14 w-14 flex items-center justify-center border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 bg-white dark:bg-slate-900 rounded-full">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                                         className="size-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z"/>
                                                    </svg>
                                                </div>
                                                <div className="pl-20 space-y-1">
                                                    <div className="text-xs text-slate-500 uppercase">Linz <span
                                                        className="text-slate-400 dark:text-slate-600">·</span> Austria
                                                    </div>
                                                    <div
                                                        className="font-aspekta font-[650] text-slate-800 dark:text-slate-100">Digital
                                                        Strategy and Operations Manager
                                                    </div>
                                                    <div className="text-sm text-slate-500 dark:text-slate-400">Focused
                                                        on market research, business development, and process
                                                        digitalization, I work to improve operational efficiency and
                                                        identify new growth opportunities. I have led projects from
                                                        start to finish, digitalized key processes, and optimized
                                                        logistics operations to ensure fast and reliable services. I’ve
                                                        also developed customer acquisition strategies, resulting in
                                                        user base growth and higher retention.

                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        {/* Item*/}
                                        <li className="relative group">
                                            <div
                                                className="flex items-start before:absolute before:left-0 before:h-full before:w-px before:bg-slate-200 before:dark:bg-slate-800 before:self-start before:ml-[28px] before:-translate-x-1/2 before:translate-y-8 before:group-last-of-type:hidden">
                                                <div
                                                    className="absolute left-0 h-14 w-14 flex items-center justify-center border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 bg-white dark:bg-slate-900 rounded-full">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                         viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00adef"
                                                         className="size-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                              d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802"/>
                                                    </svg>
                                                </div>
                                                <div className="pl-20 space-y-1">
                                                    <div className="text-xs text-slate-500 uppercase">Guangzhou <span
                                                        className="text-slate-400 dark:text-slate-600">·</span> Hong Kong
                                                    </div>
                                                    <div
                                                        className="font-aspekta font-[650] text-slate-800 dark:text-slate-100">Business
                                                        Consultant for China
                                                    </div>

                                                    <div className="text-sm text-slate-500 dark:text-slate-400">I’ve
                                                        helped clients navigate the complexities of doing business in
                                                        the region, managing supply chains, ensuring quality through
                                                        factory audits, and offering strategic advice to optimize their
                                                        operations. My focus on improving processes and reducing risks
                                                        has helped businesses operate more efficiently and securely in
                                                        international markets.

                                                    </div>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div className="space-y-4">
                                    <h2 className="h3 font-aspekta text-slate-800 dark:text-slate-100">Let's
                                        Connect</h2>
                                    <p>
                                        <Link to='/contact' className="font-medium text-sky-500 hover:underline">Reach out </Link>
                                        and discover how we can work together to bring your
                                        ideas to life.
                                        Whether you need help with frontend development or improving your business
                                        processes, I’m here to support your goals and deliver real results. Feel free to
                                        contact me anytime!"
                                    </p>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>

                {/*Right sidebar*/}
                <aside className=" md:w-[240px] lg:w-[300px] shrink-0">

                    <div className="space-y-6">

                        {/* Programming language widget*/}
                        <ProgrammingLanguageWidget openText={openText} handleSubmit={handleSubmit} />


                        {/* Sponsor widget */}
                        <SponsorWidget/>

                        { /*Popular services widget*/}
                        <div
                            className="rounded-lg border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:rotate-1 even:-rotate-1 p-5">
                            <div className="font-aspekta font-[550] mb-3">Optimized Digital Solutions</div>
                            <ul className="space-y-3">
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Custom Applications
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Consulting on digital strategy
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Integrating e-commerce solutions
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta   text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Developing interactive websites
                                    </p>
                                </li>
                                <li className="inline-flex">
                                    <span className="text-sky-500 mr-2">—</span>
                                    <p className="font-aspekta text-sm inline-flex relative hover:text-sky-500 duration-150 ease-out before:scale-x-0 before:origin-center before:absolute before:inset-0 before:bg-sky-200 dark:before:bg-sky-500 before:opacity-30 before:-z-10 before:translate-y-1/4 before:-rotate-2 hover:before:scale-100 before:duration-150 before:ease-in-out">
                                        Providing ongoing technical support
                                    </p>
                                </li>
                            </ul>

                        </div>

                    </div>

                </aside>

            </div>
        </div>

    );
};

export default Home;
