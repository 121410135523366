import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import './index.css';
import './assets/css/style.css';
import './assets/css/theme.css';
import './assets/css/utility-patterns.css';
import './assets/css/range-slider.css';
import './assets/css/toggle-switch.css';

import Header from './components/Header';
import BurgerMenu from './components/BurgerMenu';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import TaskBoard from './pages/TaskBoard';

const App = () => {
    return (
        <Router>
            <div className="App no-scrollbar max-w-7xl mx-auto min-h-screen flex flex-col overflow-hidden">
                {/* Sidebar/Header */}
                <div className="fixed top-0 w-16 sm:w-20 md:w-24 bg-white h-screen no-scrollbar border-r border-slate-200 dark:border-slate-800 hidden sm:block">
                    <Header />
                </div>

                {/* BurgerMenu for small screens */}
                <div className="md:hidden fixed bottom-4 right-4 pt-1 px-1 rounded-lg border-r-2 bg-sky-500 z-50">
                    <BurgerMenu />
                </div>

                {/* Main Content Area */}
                <main className="flex-1 overflow-y-auto sm:ml-20 md:ml-24 p-8 no-scrollbar">
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/taskboard" element={<TaskBoard />} />
                    </Routes>
                </main>

                {/* Footer */}
                <Footer/>
            </div>
        </Router>
    );
};


export default App;