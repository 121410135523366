import React, {useEffect } from 'react';

import ProgrammingLanguageWidget from "../components/ProgrammingLanguageWidget";
import SponsorWidget from "../components/SponsorWidget";
import programmingLanguageFunction from "../hooks/programmingLanguageFunction";

const About = () => {


    const words = ['WORK !', 'HELP !'];
    const {openText, handleSubmit} = programmingLanguageFunction(words); // Use the custom hook

    const codeText = [
        "<p> Let’s work together to transform your business and unlock new opportunities in the digital world! </p>"
    ];
    const {openText: animatedCodeText} = programmingLanguageFunction(codeText, 80, 5); // Using hook for animated code text
    const splitText = animatedCodeText.split(/(<\/?p>)/g);

    useEffect(() => {
        window.scrollTo(0, 0); // Hacer scroll a la parte superior al montar el componente
    }, []);
    return (
        <div>
            <div className="grow md:flex space-y-8  md:space-y-0 md:space-x-8 pb-16 md:pb-20">
                <div className="grow">
                    <div className="max-w-[700px]">
                        <div className="space-y-10">
                            <h1 className="h1 font-aspekta mb-4">Key Interests 💡
                            </h1>
                            <div className="text-slate-500 dark:text-slate-400 space-y-8">
                                <div className="space-y-4">
                                    <h2 className="h2 font-aspekta text-slate-800 dark:text-slate-100">Frontend
                                        Development & Programming
                                    </h2>
                                    <p>
                                        I love seeing how solutions take shape on the frontend, transforming ideas and
                                        code into interfaces that users can interact with. From fetching content from
                                        the backend to displaying it visually in the browser, the process of creation is
                                        truly fascinating. I enjoy watching how each line of code contributes to a
                                        seamless, functional user experience, turning complex problems into elegant and
                                        accessible solutions. It’s amazing how development connects all the pieces to
                                        bring ideas to life!</p>
                                </div>
                                <div className="space-y-4">
                                    <h2 className="h2 font-aspekta text-slate-800 dark:text-slate-100">Project
                                        Management & Leadership

                                    </h2>
                                    <p>
                                        Bringing structure and clear direction to each project is key. Guiding projects
                                        from start to finish, I ensure that every step aligns with the team’s goals and
                                        adds real value. Effective management involves setting simple plans, keeping
                                        communication open, and making decisions that support everyone’s best work. The
                                        aim is to lead projects that achieve their targets while encouraging growth and
                                        teamwork.</p>
                                </div>
                                <div className="space-y-4">
                                    <h2 className="h2 font-aspekta text-slate-800 dark:text-slate-100">Innovation &
                                        Entrepreneurship
                                    </h2>
                                    <p>
                                        Whether you're an entrepreneur, a growing company, or someone with a fresh idea,
                                        my passion is helping you take that crucial first step. You don’t need to have
                                        everything figured out—what matters is taking action. Building something new
                                        involves continuous learning and improvement, and I’m here to offer the tools
                                        and advice needed to turn your vision into reality. Together, we can make it
                                        happen!</p>
                                </div>
                                {/* Animated Code Block */}
                                <pre
                                    className="whitespace-pre-wrap text-sm text-white bg-slate-800 p-4 rounded leading-tight relative">
                                    <code className="font-pt-mono typing ">
                                        {splitText.map((part, index) => (
                                            <span
                                                key={index}
                                                className={part.includes('<p>') || part.includes('</p>') ? 'text-yellow-400' : ''}
                                            >
                                                {part}
                                            </span>
                                        ))}
                                        <span className="cursor">|</span>
                                    </code>
                                </pre>
                                <div className="space-y-4">
                                    <h2 className="h2 font-aspekta text-slate-800 dark:text-slate-100">Digital
                                        Transformation</h2>
                                    <p>
                                        In today’s competitive landscape, digital transformation is essential for
                                        staying ahead. I’m passionate about helping businesses make the transition by
                                        following these 5 key steps: </p>

                                    <ul className="space-y-4">
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span> <strong className="font-medium text-slate-800 dark:text-slate-100">Process Digitalization: </strong> Automating manual
                                            tasks for better efficiency.</span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span> <strong className="font-medium text-slate-800 dark:text-slate-100">Cloud Adoption:</strong> Utilizing cloud platforms
                                            for secure and scalable data management.</span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span> <strong className="font-medium text-slate-800 dark:text-slate-100">Integration of Digital Tools: </strong>Streamlining decision-making with advanced software solutions like CRMs and
                                            ERPs.
                                            </span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span> <strong className="font-medium text-slate-800 dark:text-slate-100">Fostering a Culture of Innovation: </strong>Encouraging a mindset that embraces continuous innovation and technological
                                            change.
                                            </span>
                                        </li>
                                        <li className="flex items-start">
                                            <svg className="w-3 h-3 fill-current text-sky-500 mr-3 mt-1.5 shrink-0"
                                                 viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span> <strong className="font-medium text-slate-800 dark:text-slate-100">Enhancing the Digital Customer Experience: </strong>Using digital channels to optimize customer communication and service
                                            delivery.
                                            </span>
                                        </li>

                                    </ul>


                                </div>

                                <div className="space-y-4">
                                    <h2 className="h2 font-aspekta text-slate-800 dark:text-slate-100">Lean Startup
                                        Approach</h2>
                                    <p>
                                        I believe in starting fast and adapting quickly. The Lean Startup methodology
                                        drives my work, focusing on launching a Minimum Viable Product (MVP) and
                                        gathering real customer feedback. This allows rapid adjustments, saving both
                                        time and resources. The process is simple: <strong
                                        className="font-medium text-slate-800 dark:text-slate-100"> build, test, learn,
                                        improve, and repeat.</strong> Starting small, learning fast, and scaling smart
                                        are key to success in any project. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <aside className="md:w-[240px] lg:w-[300px] shrink-0">
                    <div className="space-y-6">

                        {/* Programming language widget*/}
                        <ProgrammingLanguageWidget openText={openText} handleSubmit={handleSubmit}/>

                        {/* Sponsor widget */}
                        <SponsorWidget/>

                        {/* <div
                            className="rounded-lg border border-slate-200 dark:border-slate-800 dark:bg-gradient-to-t dark:from-slate-800 dark:to-slate-800/30 odd:rotate-1 even:-rotate-1 p-5">
                            <div className="font-aspekta font-[550] mb-3">Languages</div>
                            <ul className="space-y-3">

                                <li className="flex justify-between items-center">
                                    <div className="grow inline-flex mr-1.5 truncate">
                                        <span className="text-base mr-1.5">🇪🇸</span> <span
                                        className="font-aspekta font-[500] text-sm truncate">Spanish</span>
                                    </div>
                                    <div
                                        className="shrink-0 relative w-5 h-5 rounded-full bg-[conic-gradient(theme(colors.sky.500)_100%,0,theme(colors.slate.200)_0)] dark:bg-[conic-gradient(theme(colors.sky.500)_100%,0,theme(colors.slate.700)_0)] after:absolute after:inset-0 after:rounded-full after:w-3 after:h-3 after:m-auto after:bg-white dark:after:bg-slate-800"
                                        role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </li>
                                <li className="flex justify-between items-center">
                                    <div className="grow inline-flex mr-1.5 truncate">
                                        <span className="text-base mr-1.5">🇬🇧</span> <span
                                        className="font-aspekta font-[500] text-sm truncate">English</span>
                                    </div>
                                    <div
                                        className="shrink-0 relative w-5 h-5 rounded-full bg-[conic-gradient(theme(colors.sky.500)_90%,0,theme(colors.slate.200)_0)] dark:bg-[conic-gradient(theme(colors.sky.500)_100%,0,theme(colors.slate.700)_0)] after:absolute after:inset-0 after:rounded-full after:w-3 after:h-3 after:m-auto after:bg-white dark:after:bg-slate-800"
                                        role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </li>
                                <li className="flex justify-between items-center">
                                    <div className="grow inline-flex mr-1.5 truncate">
                                        <span className="text-base mr-1.5">🇩🇪</span> <span
                                        className="font-aspekta font-[500] text-sm truncate">German</span>
                                    </div>
                                    <div
                                        className="shrink-0 relative w-5 h-5 rounded-full bg-[conic-gradient(theme(colors.sky.500)_60%,0,theme(colors.slate.200)_0)] dark:bg-[conic-gradient(theme(colors.sky.500)_100%,0,theme(colors.slate.700)_0)] after:absolute after:inset-0 after:rounded-full after:w-3 after:h-3 after:m-auto after:bg-white dark:after:bg-slate-800"
                                        role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </li>

                                <li className="flex justify-between items-center">
                                    <div className="grow inline-flex mr-1.5 truncate">
                                        <span className="text-base mr-1.5">🇨🇳</span> <span
                                        className="font-aspekta font-[500] text-sm truncate">Chinese</span>
                                    </div>
                                    <div
                                        className="shrink-0 relative w-5 h-5 rounded-full bg-[conic-gradient(theme(colors.sky.500)_50%,0,theme(colors.slate.200)_0)] dark:bg-[conic-gradient(theme(colors.sky.500)_100%,0,theme(colors.slate.700)_0)] after:absolute after:inset-0 after:rounded-full after:w-3 after:h-3 after:m-auto after:bg-white dark:after:bg-slate-800"
                                        role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </li>


                            </ul>
                        </div>*/}
                    </div>
                </aside>
            </div>
        </div>
    );
};

export default About;
